import styled from "styled-components";
import { DARK_BLUE, LIGHT_BLUE } from "../assets/colors";
import insta from "../assets/images/instaa.png";
import face from "../assets/images/face.png";
import wpp from "../assets/images/wpp.png";
import {MdEmail} from "react-icons/md";
export function Footer() {
    return (
        <Conatiner name="footer">
            <SubContainer>
                Rua Isidoro Farina, 116 - CJ José Bonifácio<br/>
                São Paulo/SP<br/>
                CEP: 08255-290<br/>
                <br/>
                O & J PRESTACAO DE SERVICOS LTDA<br/>
                CNPJ 51.879.640/0001-54
                
            </SubContainer>
            <Separator/>
            <SubContainer>
                <div>
                   <Insta src={insta} alt="logo" />
                   <p>@oej_manobrista</p> 
                </div>
                <div>
                    <Face src={face} alt="logo" />
                    <p>@oej_manobrista</p>
                </div>
                <div>
                    <Wpp src={wpp} alt="logo" />
                    <p>(11) 94033-7775</p>
                </div>
                <div>
                    <Icon/>
                    <p>oejmanoportalimpe@gmail.com</p>
                </div>               
            </SubContainer>
        </Conatiner>
    )
}

const Conatiner = styled.footer`
box-sizing: border-box;
z-index: 1;
position:relative;
left:0px;
bottom:0px;
width:100vw;
height:300px;
display:flex;
justify-content:space-between;
background-color:${DARK_BLUE};
align-items:center;
align-content:center;
`
const SubContainer = styled.div`
width:50%;
font-family: 'Roboto Mono', monospace;
font-size: 18px;
color:white;
line-height: 1.5;
display:flex;
flex-direction:column;
align-items:center;
div{
    width:60%;
    display:flex;
    flex-direction:row;
    align-items:center;
    margin-bottom:6px;
}
p{
   margin-left:10px; 
}
`
const Icon = styled(MdEmail)`
width:48px;
height:48px;
`
const Insta = styled.img`
height:55px;
width:55px;
`
const Face = styled.img`
height:51px;
width:51px;
`
const Wpp = styled.img`
height:49px;
width:49px;
`
const Separator = styled.div`
width:1.3px;
height:80%;
background-color:${LIGHT_BLUE};
`