import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ImageCarousel = ({ images, intervalTime }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, intervalTime);

    return () => clearInterval(interval);
  }, [images.length, intervalTime]);

  return (
    <Carousel>
      <img src={images[currentIndex]} alt={`Image ${currentIndex}`} />
    </Carousel>
  );
};

export default ImageCarousel;

const Carousel = styled.div`
position: relative;
width: 82vw;
max-width: 100vw; /* Defina um tamanho máximo para as imagens */
height: 600px;
margin: 0 auto;
margin-top: 80px;
// overflow: hidden;
img{
  width: 100%;
  height: 100%;
//   object-fit: cover;
}
`