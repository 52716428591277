import oj from "../../assets/images/oj.png";
import Header from "../../components/Header";
import Contacts from "../../components/Contacts";
import { Carousel, Icon, Who, WhoWeAre, WhoWeAreSubtitle, WhoWeAreText, WhoWeAreTitle } from "./style";
import { useRef } from "react";
import TypingText from "../../components/TypingText";
import { Footer } from "../../components/Footer";
export default function HomePage() {
    const images = [oj];
    const whoWeAreRef = useRef();

    const scrollToWhoWeAre = () => {
        if (whoWeAreRef.current) {
          whoWeAreRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const contacts = useRef();

    const scrollToContacts = () => {
        if (contacts.current) {
          contacts.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return(
        <>
        <Contacts />
        <Header scrollToWhoWeAre={scrollToWhoWeAre} scrollToContacts={scrollToContacts}/>
        <Carousel>
            <img src={oj} />
            <TypingText />
        </Carousel>
        <WhoWeAre ref={whoWeAreRef}>
            <WhoWeAreTitle>Quem Somos?</WhoWeAreTitle>
            <Who>
                <p>A empresa O&J nasce com o apoio de uma empresa referência no segmento, tornando-se uma forte parceira pela sua experiência e bagagem profissional. Administrada por suas fundadoras se destaca por sua conduta pró ativa e humana entendendo que pessoas são o bem mais valioso.</p>
                <p>Dentre nossos objetivos está a execução de uma prestação de serviços qualificada e de excelência valorizando seus colaboradores acreditando na força e importância do seu trabalho, pois nos preocupamos com as pessoas: colaboradores, clientes, comunidade. Mais do que prestar serviços queremos proporcionar experiências satisfatórias ao cliente com o melhor atendimento, propondo e desenvolvendo soluções que apresentam os melhores resultados possíveis.</p>
            </Who>
            <WhoWeAreSubtitle>
                <Icon/>
                Missão
            </WhoWeAreSubtitle>
            <WhoWeAreText>
                Promover soluções na prestação de serviços com mão de obra qualificada, atendimento individualizado conforme necessidade de cada cliente. Aliado a valorização dos profissionais e clientes.
            </WhoWeAreText>
            <WhoWeAreSubtitle>
                <Icon/>
                Visão
            </WhoWeAreSubtitle>
            <WhoWeAreText>
                Ser reconhecida como uma empresa de serviços terceirizados que tem como compromissos o respeito, a competência e a responsabilidade.
            </WhoWeAreText>
            <WhoWeAreSubtitle>
                <Icon/>
                Valores
            </WhoWeAreSubtitle>
            <WhoWeAreText>
                Ética e respeito na relação com os concorrentes, clientes e parceiros. Valorização dos funcionários. Mostrando a importância de servir e cuidar das pessoas com excelência independente de hierarquia.
            </WhoWeAreText>
        </WhoWeAre>
        <Footer ref={contacts}/>
        </>
        
    );
}