import React from "react";
import styled from "styled-components";
import RotatingText from "react-rotating-text";

const StyledTypingText = styled(RotatingText)`
position: absolute;
top: 60%;
left: 15%;
width: 70%;
text-align: center;
line-height: 1.5;
font-family: "Roboto Mono", monospace;
font-size: 24px;
`;

export default function TypingText() {
  return (
    <StyledTypingText
      items={["Para nós o trabalho vai muito além de um bom desempenho na execução. É muito mais sobre proporcionar experiências agradáveis e satisfatórias para as pessoas neste ambiente."]}
      pause={8000}
      cursor={false}
      color="white"
      eraseMode="overwrite"
    />
  );
}
