import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/Home/index.js";
import ServicesPage from "./pages/Services/index.js";
import BudgetPage from "./pages/Budget/index.js";
import WorkWithUsPage from "./pages/Work/index.js";
function App() {

  return (
    <BrowserRouter>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/servicos" element={<ServicesPage />} />
        <Route path="/orcamento" element={<BudgetPage />} />
        <Route path="/trabalhe-conosco" element={<WorkWithUsPage />} />
      </Routes>

    </BrowserRouter>
  );
}

export default App;
