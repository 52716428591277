import styled from "styled-components";
import { LIGHT_BLUE } from "../../assets/colors";

export const Text = styled.div`
font-family: 'Roboto Mono', monospace;
font-size: 20px;
width: 60%;
height: 60px;
padding: 10px;
margin: 100px auto;
background: ${LIGHT_BLUE};
color:white;
border-radius:10px;
`