import Contacts from "../../components/Contacts";
import Header from "../../components/Header";
import ImageCarousel from "../../components/ImageCrousel";
import cleaning from "../../assets/images/limpeza.png";
import valetParking from "../../assets/images/manobrista.jpeg";
import concierge from "../../assets/images/porteiro.jpeg";
import { useState } from "react";
import emailjs from '@emailjs/browser';
import { Button, Input, Label, Option, Select, SubContainer, TextArea } from "./style";
import {Footer} from "../../components/Footer";
export default function BudgetPage() {
    const images = [cleaning, valetParking, concierge];
    const initialState = {
        name: "",
        cep: "",
        email: "",
        cel: "",
        adress: "",
        complement: "",
        number: "",
        neighborhood: "",
        text: "",
        className: ""
    };
    const [formData, setFormData] = useState(initialState);
    const services = ["Portaria 24 horas", "Limpeza e Conservação", "Manobrista", "Segurança e Vigilância", "Mais de um Serviço"];

    function handleForm(e) {
        e.preventDefault();
        const templateParams = {
            from_name: formData.name,
            to_name: formData.name,
            message: formData.text,
            service: formData.className === "" ? "Não escolheu serviço" : formData.className,
            adress: `${formData.adress}, número: ${formData.number}, bairro: ${formData.neighborhood} e complemento: ${formData.complement === "" ? "Sem complemento" : formData.complement}`,
            cel: formData.cel,
            email: formData.email
        }
        emailjs.send("service_ogyl8b2", "template_k1t38fu", templateParams, "E89RwBQCI6KX_1JgI")
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            setFormData(initialState);
        })
        .catch((err) => {
            console.log('Erro:', err);
        })
    }
    return (
        <>
        <Contacts />
        <Header />
        <ImageCarousel images={images} intervalTime={3000}/>
        <SubContainer onSubmit={handleForm}>
            <Label htmlFor="name">Nome Completo</Label>
            <Input
                id="name"
                value={formData.name}
                required
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
            
            <Label htmlFor="email">E-mail</Label>
            <Input
                id="email"
                type="email"
                required
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
            <Label htmlFor="cel">Celular</Label>
            <Input
                id="cel"
                mask={'(99) 99999-9999'}
                required
                value={formData.cel}
                onChange={(e) => setFormData({ ...formData, cel: e.target.value })}
            />
            <Label htmlFor="cep">CEP</Label>
            <Input
                id="cep"
                mask={'99999-999'}
                required
                value={formData.cep}
                onChange={(e) => setFormData({ ...formData, cep: e.target.value })}
            />
            <Label htmlFor="adress">Endereço</Label>
            <Input
                id="adress"
                value={formData.adress}
                required
                onChange={(e) => setFormData({ ...formData, adress: e.target.value })}
            />
            <Label htmlFor="number">Número</Label>
            <Input
                id="number"
                value={formData.number}
                required
                type="number"
                onChange={(e) => setFormData({ ...formData, number: e.target.value })}
            />
            <Label htmlFor="neighborhood">Bairro</Label>
            <Input
                id="neighborhood"
                value={formData.neighborhood}
                required
                onChange={(e) => setFormData({ ...formData, neighborhood: e.target.value })}
            />
            <Label htmlFor="complement">Complemento</Label>
            <Input
                id="complement"
                value={formData.complement}
                onChange={(e) => setFormData({ ...formData, complement: e.target.value })}
            />
            <Label htmlFor="text">Escreva sua mensagem</Label>
            <TextArea
                id="text"
                value={formData.text}
                onChange={(e) => setFormData({ ...formData, text: e.target.value })}
            />
            <Select
                value={formData.className}
                onChange={(e) =>
                setFormData({ ...formData, className: e.target.value })
                }
            >
                <Option value="">Selecione o serviço (opcional)</Option>
                {services.map((s, index) => (
                    <Option key={index} value={s}>{s}</Option>
                ))}
            </Select>
            <Button>ENVIAR EMAIL</Button>
        </SubContainer>
        <Footer />
        </>
    );
}