import styled from "styled-components";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-scroll';

export default function Header ({scrollToWhoWeAre, scrollToContacts}){
    const nav = useNavigate();
    const currentPathname = window.location.pathname;

    return (
        <TopBar>
            <StyledLogo/>
            <Menu>
                <Options onClick={() => currentPathname === "/"
                            ? scrollToWhoWeAre()
                            : nav("/")}>QUEM SOMOS</Options>
                <Options onClick={() => nav('/servicos')}>SERVIÇOS</Options>
                <Options onClick={()  => nav('/orcamento')}>ORÇAMENTO</Options>
                <Link to="footer" spy={true} smooth={true} duration={500}><Options>CONTATOS</Options></Link>
                <Options onClick={()  => nav('/trabalhe-conosco')}>TRABALHE CONOSCO</Options>
            </Menu>
        </TopBar>
    )
}

const TopBar = styled.header`
    width: 100vw;
    height: 180px;
    margin-top: 60px;
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    background-color: white;
    font-family: 'Roboto Mono', monospace;
    font-weight: 400;
    font-size: 62px;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.5);
`

const StyledLogo = styled(Logo)`
    width: 130px;
    height: auto;
`

const Menu = styled.div`
    display: flex;
    align-items: center;
    margin-left: 50px;
`

const Options = styled.p`
    font-family: 'Roboto Mono', monospace;
    font-weight: 400;
    font-size: 24px;
    margin-right: 30px;
`
