import { styled } from "styled-components";
import {AiFillCaretRight} from 'react-icons/ai';
import { DARK_BLUE, LIGHT_BLUE } from "../../assets/colors";

export const WhoWeAre = styled.div`
box-sizing: border-box;
width: 100vw;
padding: 20px;
display: flex;
flex-direction: column;
margin-top: 100px;
margin-bottom: 80px;
`
export const Carousel = styled.div`
width: 100vw;
height: 600px;
margin: 0 auto;
img{
  width: 100%;
  height: 100%;
}
`
export const WhoWeAreTitle = styled.h1`
width: 80%;
font-family: 'Roboto Mono', monospace;
font-weight: 700;
font-size: 40px;
margin: 0 auto;
margin-bottom: 20px;
`
export const WhoWeAreSubtitle = styled.div`
width: 50%;
font-family: 'Roboto Mono', monospace;
font-weight: 400;
font-size: 30px;
margin: 0 auto;
margin-bottom: 10px;
`
export const WhoWeAreText = styled.div`
background:${LIGHT_BLUE};
width: 50%;
height: 100%;
text-align: justify;
line-height: 1.5;
letter-spacing: 0.5px;
font-family: 'Roboto Mono', monospace;
font-weight: normal;
font-size: 20px;
color: white;
padding: 10px;
border-radius: 10px;
margin: 0 auto;
margin-bottom: 50px;
box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
`
export const Who = styled.div`
width: 80%;
height: 100%;
text-align: justify;
line-height: 1.5;
letter-spacing: 0.5px;
font-family: 'Roboto Mono', monospace;
font-weight: normal;
font-size: 20px;
padding: 10px;
border-radius: 10px;
margin: 0 auto;
margin-bottom: 50px;
p{
    margin-bottom: 20px;
}
`

export const Icon = styled(AiFillCaretRight)`
width: 30px;
height: 30px;
color: ${DARK_BLUE};
margin-right: 10px;
`
