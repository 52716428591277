import Contacts from "../../components/Contacts";
import Header from "../../components/Header";
import ImageCarousel from "../../components/ImageCrousel";
import cleaning from "../../assets/images/limpeza.png";
import valetParking from "../../assets/images/manobrista.jpeg";
import concierge from "../../assets/images/porteiro.jpeg";
import { Car, Caracteristic, Caracteristics, Clean, Description, House, Icon, Name, Security, Service, Services, SubTitle, Text, Title, Wrapper } from "./style";
import { Footer } from "../../components/Footer";

export default function ServicesPage() {
    const images = [cleaning, valetParking, concierge];

    return (
        <>
        <Contacts />
        <Header />
        <ImageCarousel images={images} intervalTime={3000}/>
        <Services>
            <Title>Nossos Serviços</Title>
            <Wrapper>
                <Service>
                <House/>
                <SubTitle>Portaria 24 horas</SubTitle> 
                </Service>
                <Service>
                <Car/>
                <SubTitle>Manobrista</SubTitle> 
                </Service>
                <Service>
                <Clean/>
                <SubTitle>Limpeza e conservação</SubTitle> 
                </Service>
                <Service>
                <Security/>
                <SubTitle>Segurança e  vigilância</SubTitle> 
                </Service>
            </Wrapper>
            <Caracteristics>
                <Caracteristic>
                    <Name>
                        <Icon/>
                        <Text>Experiência</Text>
                    </Name>
                    <Description>Como especialistas, proporcionamos o melhor atendimento, propomos e desenvolvemos as soluções que apresentam os melhores resultados.</Description>
                </Caracteristic>
                <Caracteristic>
                    <Name>
                        <Icon/>
                        <Text>Qualidade</Text>
                    </Name>
                    <Description>Nossos profissionais são treinados para obter o máximo desempenho em suas atividades, sendo uma constante a gentileza e presteza com o público em geral.</Description>
                </Caracteristic>
                <Caracteristic>
                    <Name>
                        <Icon/>
                        <Text>Responsabilidade</Text>
                    </Name>
                    <Description>Atuamos com respeito e transparência aos acordos estabelecidos, promovendo vínculos duradouros e de confiança com nosso cliente.</Description>
                </Caracteristic>
                <Caracteristic>
                    <Name>
                        <Icon/>
                        <Text>Diferenciais</Text>
                    </Name>
                    <Description>Realizamos treinamentos periódicos de reciclagem por gestores e supervisores capacitados no próprio local de trabalho. Os participantes são todos certificados.</Description>
                </Caracteristic>
            </Caracteristics>
            
        </Services>
        <Footer />
        </>
    );
}