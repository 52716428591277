import styled from "styled-components";
import { BLUE, DARK_BLUE, LIGHT_BLUE } from "../../assets/colors";
import ReactInputMask from "react-input-mask";

export const SubContainer = styled.form`
display:flex;
flex-direction:column;
background: ${LIGHT_BLUE};
width:50vw;
height:auto;
padding: 30px;
border-radius: 20px;
margin: 0 auto;
margin-top:70px;
margin-bottom: 150px;
opacity: 0.9;
`
export const Input = styled(ReactInputMask)`
font-family: 'Roboto Mono', monospace;
font-size: 20px;
width: 80%;
height: 20px;
margin: 2px;
border-radius: 4px;
padding: 10px;
border: solid 0.3px ${DARK_BLUE};
margin: 0 auto;
`
export const TextArea = styled.textarea`
font-family: 'Roboto Mono', monospace;
font-size: 20px;
width: 80%;
height: 60px;
margin: 2px;
border-radius: 4px;
padding: 10px;
border: solid 0.3px ${DARK_BLUE};
margin: 0 auto;
`
export const Label = styled.label`
width: 80%;
font-family: 'Roboto Mono', monospace;
font-size: 20px;
font-weight: 400;
color: black;
margin: 20px auto;
`
export const Button = styled.button`
border: none;
border-radius: 4.63636px;
width: 40%;
height: 60px;
background-color:#53616B;
font-family: 'Roboto Mono', monospace;
font-size: 1rem;
color:#fff;
font-weight: 600;
background: ${DARK_BLUE};
margin: 0 auto;
@media screen and (max-width: 900px){
    margin: 10px;
    height: 80px;
    width: 80%;
    position: static;
}
`
export const Select = styled.select`
font-family: 'Roboto Mono', monospace;
width:83%;
height:40px;
border-radius:5px;
border:none;
font-size: 16px;
color:black;
margin: 20px auto;
border: solid 0.3px ${DARK_BLUE};
`
export const Option = styled.option`
font-family: 'Roboto Mono', monospace;
font-size: 20px;
`