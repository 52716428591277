import styled from "styled-components";
import { BACKGROUND, DARK_BLUE, LIGHT_BLUE } from "../assets/colors";
import insta from "../assets/images/instaa.png";
import face from "../assets/images/face.png";
import wpp from "../assets/images/wpp.png";
import {TfiEmail} from "react-icons/tfi";
import { Link } from "react-router-dom";


export default function Contacts (){
    const recipient = 'oejmanoportalimpe@gmail.com'; // E-mail do destinatário
    const subject = 'Contato'; // Assunto do e-mail

    const mailtoLink = `https://mail.google.com/mail/?view=cm&to=${recipient}&su=${encodeURIComponent(subject)}`;

    return (
        <TopBar>
            <Email>
                <Link to={mailtoLink} target="_blank"><Icon /></Link>
                <h3>oejmanoportalimpe@gmail.com</h3> 
            </Email>
            <Social>
                <Link to={"https://instagram.com/oej_manobrista?utm_source=qr&igshid=NGExMmI2YTkyZg%3D%3D"} target="_blank"><Insta src={insta} alt="logo" /></Link>
                <Face src={face} alt="logo" />
                <Link to={"https://wa.me/5511940337775?text=Ol%C3%A1%2C+estou+interessado%28a%29+nos+servi%C3%A7os+da+O%26J.+Pode+me+passar+mais+informa%C3%A7%C3%B5es%3F"} target="_blank"><Wpp src={wpp} alt="logo" /></Link> 
            </Social>
            
        </TopBar>
    )
}


const TopBar = styled.header`
box-sizing: border-box;
z-index: 1;
position:fixed;
left:0px;
top:0px;
width:100vw;
height:60px;
display:flex;
justify-content:space-between;
background-color:${DARK_BLUE};
align-items:center;
align-content:center;
`
const Email = styled.div`
display:flex;
align-items:center;
justify-content:center;
width:300px;
heigth: 100%;
text-decoration:none;
font-family: 'Roboto Mono', monospace;
font-weight: 400;
font-size: 62px;
h3{
    width: 230px;
    height: 100%;
    font-size: 16px;
    color:white;
    margin-left:1rem;
    text-decoration:none;
}
`
const Social = styled.div`
width: 180px;
height: 100%;
padding:10px;
// margin-right:10px;
`
const Insta = styled.img`
height:55px;
width:55px;
`
const Face = styled.img`
height:51px;
width:51px;
`
const Wpp = styled.img`
height:49px;
width:49px;
`
const Icon = styled(TfiEmail)`
font-size:1.8rem;
color:white;
margin-left:1rem;
margin-bottom: 0.5rem;
`