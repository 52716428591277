import Contacts from "../../components/Contacts";
import Header from "../../components/Header";
import ImageCarousel from "../../components/ImageCrousel";
import cleaning from "../../assets/images/limpeza.png";
import valetParking from "../../assets/images/manobrista.jpeg";
import concierge from "../../assets/images/porteiro.jpeg";
import { Link } from "react-router-dom";
import { Text } from "./style";
import {Footer} from '../../components/Footer';
export default function WorkWithUsPage() {
    const images = [cleaning, valetParking, concierge];
    const recipient = 'oejmanoportalimpe@gmail.com'; // E-mail do destinatário
    const subject = 'Contato'; // Assunto do e-mail

    const mailtoLink = `https://mail.google.com/mail/?view=cm&to=${recipient}&su=${encodeURIComponent(subject)}`;
    return (
        <>
        <Contacts />
        <Header />
        <ImageCarousel images={images} intervalTime={3000}/>
        <Text>Envie seu currículo no formato pdf para nosso email: <Link to={mailtoLink} target="_blank">oejmanoportalimpe@gmail.com</Link> com o assunto da mensagem "Trabalhe Conosco - seu nome".</Text>
        <Footer/>
        </>
    );
}