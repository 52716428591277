import { styled } from "styled-components";
import {BiBuildingHouse} from "react-icons/bi";
import {AiOutlineCar} from "react-icons/ai";
import {GiVacuumCleaner} from "react-icons/gi";
import {MdSecurity} from "react-icons/md";
import { LIGHT_BLUE } from "../../assets/colors";
import {BsFillCheckCircleFill} from "react-icons/bs";

export const Services = styled.div`
box-sizing: border-box;
width: 100vw;
background: #EEEEEE;
margin-top: 80px;
padding: 20px;
`
export const Wrapper = styled.div`
box-sizing: border-box;
display: flex;
wrap: wrap;
padding: 10px;
`
export const Title = styled.h1`
width: 100%;
font-family: 'Roboto Mono', monospace;
font-weight: 700;
font-size: 40px;
margin: 0 auto;
margin-bottom: 70px;
margin-top: 20px;
text-align: center;
`
export const House = styled(BiBuildingHouse)`
width:40px;
height: 40px;
`
export const Car = styled(AiOutlineCar)`
width:40px;
height: 40px;
`
export const Clean = styled(GiVacuumCleaner)`
width:40px;
height: 40px;
`
export const Security = styled(MdSecurity)`
width:40px;
height: 40px;
`
export const Service = styled.div`
width: 340px;
display: flex;
align-items: center;
padding: 10px;
margin: 0 auto;
margin-top: 40px;
border-radius: 10px;
border: 2px solid ${LIGHT_BLUE};
`
export const SubTitle = styled.h2`
font-family: 'Roboto Mono', monospace;
font-weight: 400;
font-size: 20px;
margin-left: 20px;
font-weight: bold;
`
export const Caracteristics = styled.div`
box-sizing: border-box;
display: flex;
flex-wrap: wrap;
gap: 40px;
margin-top: 70px;
margin-bottom: 90px;
padding: 50px;
`
export const Caracteristic = styled.div`
width: 30%;
display: flex;
flex-direction: column;
`
export const Name = styled.div`
display: flex;
align-items: center;
`
export const Text = styled.h2`
font-family: 'Roboto Mono', monospace;
font-weight: 400;
font-size: 24px;
margin-left: 15px;
`

export const Description = styled.p`
width: 90%;
font-family: 'Roboto Mono', monospace;
font-weight: 400;
font-size: 20px;
letter-spacing: 0.5px;
line-height: 1.8;
text-align: justify;
margin: 0 auto;
margin-top: 10px;
`
export const Icon = styled(BsFillCheckCircleFill)`
width:40px;
height: 40px;
color: green;
`

